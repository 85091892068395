<template>
  <page-template title="Kontakt">
    <p>
      <b>Niepubliczne Przedszkole Montessori Perełka</b>
    </p>
    <p>
      telefon:<b>
        +48 697 019 040 (w sprawie rekrutacji prosimy wyłącznie o kontakt
        mailowy)</b
      >
    </p>
    <p>e-mail:<b> biuro@perelkawroclaw.pl</b></p>
    <p>adres:<b> ul. A.Vespucciego 6a, 51-504 Wrocław</b> (osiedle Olimpia Port, Swojczyce)</p>
    <!-- <GmapMap
      :options="{
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false,
        styles: [
          {
            featureType: 'poi',
            elementType: 'labels',
            stylers: [{ visibility: 'off' }],
          },
        ],
      }"
      :center="{ lat: 51.108054859551174, lng: 17.12213740623327}"
      :zoom="15"
      map-type-id="roadmap"
      style="width: 100%; height: 500px"
    >
    
      <GmapMarker 
        :position="{ lat: 51.108054859551174, lng: 17.12213740623327}"
        />
    </GmapMap> -->
  </page-template>
</template>
<script>
import PageTemplate from "../components/PageTemplate.vue";
export default {
  components: { PageTemplate },
};
</script>
